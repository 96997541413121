<template>
  <div>
    <el-container>
      <el-main>
        <AlipayLogo></AlipayLogo>
        <el-row>
          <Qrcode :content="qrUrl" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo"><CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/></div>
          <div class="orderInfo">订单号：<el-tag size="small">{{ orderNo }}</el-tag></div>
        </el-row>
        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
        </el-row>
        <p style="font-size: 20px;font-weight: bold;color:red;text-align: left">
          如遇风险提醒，请继续完成支付，即可到账<br/>
          如弹出加好友提示，无需添加好友直接点击继续支付  即可付款成功
        </p>
        <el-row>
          <pay-tips></pay-tips>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";

export default {
  name: 'AlipayUid',
  components: {
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      qrUrl:'',
      money: 0,
      closeTime: '',
      timeOut:false,
      countDownFlag: true,
      paySuccessFlag:false,
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        let qrcode = data.pathContent
        if(qrcode.includes("alipay.com")){
          //如果是pdd代付类型
          //window.location.href = qrcode
          if (qrcode.includes("biz_no=")){
            this.h5Url = this.$utils.getXyH52(qrcode)
            this.qrUrl = "https://mclient.alipay.com/h5/peerpay.htm?enableWK=YES&biz_no=" + qrcode.match(/biz_no=([^&]+)&app_name/)[1] + "&app_name=tb&sc=card&__webview_options__=pd%3dNO"
          } else {
            this.h5Url = this.$utils.getZhouzhuan(qrcode)
            this.qrUrl = qrcode
          }
        }else{
          this.h5Url = this.$utils.getXyH5(data.pathContent)
          this.qrUrl = data.pathContent
        }

        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd(){
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data){
      if(data.code !== 0){
        return
      }
      if(data.data.state === "TIMEOUT"){
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if(data.data.state === "OOK"){
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo,this.handleCheckOrder)
  },
}
</script>
