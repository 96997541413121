<template>
  <div>
    <el-container>
      <el-main>
<!--        <AlipayLogo></AlipayLogo>-->
        <el-row>
          <Qrcode :content="qrContent" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <img :src="path" width="80%" alt="" v-show="pathShow">
        </el-row>
        <el-row>
          <p style="font-size: 12px;color: red">1.截图保存二维码，点击复制金额</p>
          <p style="font-size: 12px;color: red">2.支付宝打开扫一扫，从相册选择当前二维码付款</p>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元
          <el-button type="primary" class="copy" :data-clipboard-text="money" @click="copyMoney" size="mini">
            复制金额</el-button>
          </div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
        </el-row>
        <el-row>
          <pay-tips></pay-tips>
        </el-row>
        <el-dialog
            title="请输入付款人姓名,不可乱填!"
            width="90%"
            :visible="dialogFlag"
            :show-close="dialogShowClose"
            center
            >
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="订单id" prop="orderId" v-show="false">
              <el-input v-model="ruleForm.orderId"></el-input>
            </el-form-item>
            <el-form-item label="付款人姓名" prop="payerName">
              <el-input v-model="ruleForm.payerName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">提 交</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import ClipboardJS from "clipboard";

export default {
  name: 'ManualAlipayUid',
  components: {
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      pathShow:false,
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      qrContent: '',
      dialogFlag: true,
      dialogShowClose: false,
      path:'',
      ruleForm: {
        orderId: '',
        payerName: ''
      },
      rules: {
        orderId: [
          {required: true, message: '请输入订单id', trigger: 'blur'},
        ],
        payerName: [
          {required: true, message: '请输入付款人姓名', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        //this.h5Url = this.$utils.getAlipayUidH5V2(data.money, data.uid, this.orderNo)
        this.money = data.money
        //上传的是oss图片
        if(data.uploadType === "PATH"){
          console.log("img")
          this.path = data.path
          this.pathShow = true
        }else{
          console.log("qrcode")
          this.qrContent = data.pathUnq
          this.qrShow = true
        }

        this.ruleForm.orderId = data.orderId
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //提交表单
    submitForm(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Request.post("/show/orderUser",this.ruleForm).then(res => {
            if (res.code !== 0) {
              this.$message({message: res.msg, type: 'error',})
              return
            }
            //关闭弹窗
            this.dialogFlag = false
          });
        } else {
          return false;
        }
      });
    },
    copyMoney(){
      const clipboard = new ClipboardJS('.copy');
      clipboard.on('success', (e) => {
        this.$message({
          message: "已复制金额，请打开付款时直接粘贴", type: "success", duration: 2000
        })
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制金额失败，请手动填写!")
        clipboard.destroy(); // 取消事件监听
      });
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
